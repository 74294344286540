import React, { useState, useEffect } from "react"
import Fade from "@material-ui/core/Fade"
import Button from "@material-ui/core/Button"

import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
  link: {
    textDecoration: "none",
    display: "block",
  },
})

const TicketsButton = ({ classes }) => {
  const [hide, setHide] = useState(false)

  const hideBar = () => {
    let prev = 0
    window.scrollY > prev ? setHide(true) : setHide(false)

    prev = window.scrollY
  }

  useEffect(() => {
    window.addEventListener("scroll", hideBar)
    return () => {
      window.removeEventListener("scroll", hideBar)
    }
  })

  return (
    <Fade in={hide}>
        <a href="https://shops.link2ticket.nl/13H7aa" className={classes.link}>
        <Button variant="contained" size="large" color="secondary">
            Koop Tickets
        </Button>
        </a>
    </Fade>
  )
}

export default withStyles(styles)(TicketsButton)
